import { IInteractionMetricsAvailable } from "./interactions";

export enum InteractionsAnalyticsXAxisGroupValues {
    TIME = "time",
    OTHER = "other",
}
export enum InteractionsAnalyticsXAxisValues {
    MINUTES = "MINUTE",
    HOURS = "HOUR",
    DAYS = "DAY",
    WEEKS = "WEEK",
    MONTHS = "MONTH",
    LANGUAGES = "LANGUAGE",
    AGENTS = "AGENT",
}

export interface IMetric {
    key: string;
    dataKey: string;
    measurementKey: string;
    group: string | null;
    visibleText: string;
    type: "count" | "average";
    includeRatio?: boolean;
}

export enum SplitBy {
    AGENT = "agent",
    LANGUAGE = "language",
    INTERACTION_TYPE = "interactionType",
}

export interface IInteractionsAnalyticsGetQueryParameters {
    readonly groupBy: InteractionsAnalyticsXAxisValues;
    readonly measurement: string;
    readonly splitBy: SplitBy;
    readonly startDateTime?: Date;
    readonly endDateTime?: Date;
}

export type IInteractionsAnalyticsDataItem = Record<string, any>;
export type IInteractionsAnalyticsData = Array<IInteractionsAnalyticsDataItem>;
export type IOldInteractionsAnalyticsResponse = IInteractionsAnalyticsData;

export type ISimpleAggregate = number;
export type IGroupedAggregate = Record<string, Record<string, ISimpleAggregate>>;
export interface IInteractionAnalyticsAggregates {
    [key: string]: ISimpleAggregate | IGroupedAggregate;
}

export interface INewInteractionsAnalyticsResponse {
    data: IInteractionsAnalyticsData;
    aggregates: IInteractionAnalyticsAggregates;
}

export type IInteractionsAnalyticsResponse = IOldInteractionsAnalyticsResponse | INewInteractionsAnalyticsResponse;

export interface IInteractionsAnalyticsMetricsGetQueryParameters {}
export type IInteractionAnalyticsMetricsResponse = IInteractionMetricsAvailable;
