/**
 * Function to create a debounced version of a given function.
 * The debounced function delays the invocation of the original function until after a specified delay.
 * @param {function} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds before invoking the function (default: 500).
 * @returns {function} The debounced version of the provided function.
 */
export const debounce = <T extends (...args: any) => ReturnType<T>>(func: T, delay = 500) => {
    let timeoutId: NodeJS.Timeout;

    return (...args: Parameters<T>) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};
