import classNames from "classnames";
import { FunctionComponent } from "react";

import "./box.scss";
import { IProps } from "./box.types";

/**
 * A basic container with some styling.
 * @param props.children  Box content.
 * @param props.className Optional extra class name(s) to add.
 * @param props.boxRef    Optional reference to the box element.
 * @returns               Content in a container.
 */
const Box: FunctionComponent<IProps> = ({ children, className, boxRef }) => {
    return (
        <div className={classNames("box", className)} role="group" ref={boxRef}>
            {children}
        </div>
    );
};

export default Box;
