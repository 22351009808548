import { format } from "date-fns";

/**
 * Format a date using the browser's locale. Only change the date format for the US locale.
 * @param date The date to format.
 * @param options The options to pass to the `Intl.DateTimeFormat` constructor.
 */
export const formatDate = (
    date: Date,
    options: { explicitFormat?: string; intlOptions?: Intl.DateTimeFormatOptions } = {
        intlOptions: { dateStyle: "short" },
    }
) => {
    try {
        if (typeof options.explicitFormat !== "undefined") {
            return format(date, options.explicitFormat);
        }

        const locale = navigator.language === "en-US" ? "en-US" : "en-GB";
        return new Intl.DateTimeFormat(locale, options.intlOptions).format(date);
    } catch (error) {
        return "-";
    }
};
