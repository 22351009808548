import { memo, useEffect, useMemo, useState } from "react";
import { useField } from "formik";
import { IProps } from "./select-paginated.types";
import { debounce } from "@edgetier/utilities";
import SelectField from "../select-field";

/**
 * Component for a paginated select input that fetches options based on search input.
 * @param {function} props.onSearch                 Function to call when searching for options.
 * @param {object} props.data                       The data containing options for the select input.
 * @param {object[]} props.selectedData             The currently selected options.
 * @param {number} props.debounceSearchMiliseconds  The debounce delay for search input (default: 500).
 * @param {string} props.name                       The name of the select input.
 * @param {function} props.getValue                 Function to extract the value from an option object.
 * @returns {JSX.Element}   PaginatedSelect component.
 */
const SelectPaginated = <Option extends {}, Value extends {} = string>({
    onSearch,
    data,
    selectedData,
    debounceSearchMilliseconds = 500,
    name,
    getValue,
    ...selectProps
}: IProps<Option, Value>) => {
    const [{ value }] = useField<Value | Value[] | undefined>({ name });
    const [isRetrying, setIsRetrying] = useState(false);
    const values = useMemo(() => (Array.isArray(value) ? value : typeof value !== "undefined" ? [value] : []), [value]);
    const items = useMemo(() => {
        return data.data?.pages.flatMap((page) => page.items) ?? [];
    }, [data.data]);

    // Optimization to get the selected items from the current fetched pages.
    const parsedSelectedItems = useMemo(() => {
        const selectedValuesInData: Value[] = [];
        const itemsInData: Option[] = [];
        values.forEach((element) => {
            const found = items.find((item) => getValue(item) === element);
            if (found) {
                selectedValuesInData.push(element);
                itemsInData.push(found);
            }
        });
        if (selectedValuesInData.length < values.length) {
            // There is selected data that wasn't available in the loaded pages.
            const pendingValues = values.filter((v) => !selectedValuesInData.includes(v));
            pendingValues.forEach((element) => {
                const found = selectedData.data?.find((item) => getValue(item) === element);
                if (found) {
                    itemsInData.push(found);
                }
            });
        }
        return itemsInData;
    }, [values, items, selectedData.data, getValue]);

    const areAllFetchedItemsSelected = useMemo(() => {
        return items.every((item) => parsedSelectedItems.includes(item));
    }, [items, parsedSelectedItems]);

    useEffect(() => {
        if (areAllFetchedItemsSelected && data.hasNextPage && !data.isFetchingNextPage && !data.isError) {
            data.fetchNextPage();
        }
    }, [areAllFetchedItemsSelected, data]);

    return (
        <SelectField<Option, Value>
            name={name}
            getValue={getValue}
            items={items}
            disableInternalFilter
            isLoading={data.isLoading || isRetrying || (areAllFetchedItemsSelected && data.hasNextPage)}
            isSortedAlready
            isPaginated
            onInputChange={debounce((inputValue) => {
                onSearch?.(inputValue || undefined);
            }, debounceSearchMilliseconds)}
            hasNextPage={data.hasNextPage ?? false}
            isNextPageLoading={data.isFetchingNextPage ?? false}
            isNextPageError={data.isError ?? false}
            onRetry={async () => {
                setIsRetrying(true);
                await data.refetch();
                setIsRetrying(false);
            }}
            onScrollEndReached={() => {
                if (!data.isFetchingNextPage && data.hasNextPage && !data.isError) {
                    return data.fetchNextPage();
                }
            }}
            selectedItems={parsedSelectedItems}
            {...selectProps}
        />
    );
};

export default memo(SelectPaginated) as typeof SelectPaginated;
