import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import { IProps } from "./icon-title.types";

/**
 * Display an icon with a title next to it
 * @param props.title The title to display
 * @param props.icon  The icon to display
 * @param props.spin  If the icon should
 *
 * @return Icon titled with an icon and a title
 */
const IconTitle: FunctionComponent<IProps> = ({ title, ...other }) => {
    return (
        <div className="icon-title">
            <FontAwesomeIcon name={title} {...other} />
            {title}
        </div>
    );
};

export default IconTitle;
