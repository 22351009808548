import { FunctionComponent, memo } from "react";
import { IProps } from "./ai-summary-icon.types";
import classNames from "classnames";

/**
 * Custom icon to be used for AI related features
 * @param className optional param to apply classNames to icon to style it.
 * @returns {JSX.Element} AiSummaryIcon component.
 */
const AiSummaryIcon: FunctionComponent<IProps> = ({ className, svgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className={classNames(className)}
            {...svgProps}
        >
            <circle cx="10" cy="3" r="3" transform="rotate(-90 10 3)" fill="url(#paint0_linear_6661_1140)" />
            <circle cx="10" cy="17" r="3" transform="rotate(-90 10 17)" fill="url(#paint1_linear_6661_1140)" />
            <circle cx="3" cy="10" r="3" transform="rotate(-90 3 10)" fill="url(#paint2_linear_6661_1140)" />
            <circle cx="17" cy="10" r="3" transform="rotate(-90 17 10)" fill="url(#paint3_linear_6661_1140)" />
            <defs>
                <linearGradient
                    id="paint0_linear_6661_1140"
                    x1="14.4727"
                    y1="13.8001"
                    x2="-7.54152"
                    y2="13.8816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BD36E" />
                    <stop offset="1" stopColor="#419BDC" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_6661_1140"
                    x1="26.1454"
                    y1="26.3273"
                    x2="8.1755"
                    y2="26.5333"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BD36E" />
                    <stop offset="1" stopColor="#419BDC" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_6661_1140"
                    x1="12.3271"
                    y1="27.1272"
                    x2="-7.14385"
                    y2="26.781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BD36E" />
                    <stop offset="1" stopColor="#419BDC" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_6661_1140"
                    x1="25.8362"
                    y1="10.7624"
                    x2="9.13791"
                    y2="10.7545"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BD36E" />
                    <stop offset="1" stopColor="#419BDC" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default memo(AiSummaryIcon);
