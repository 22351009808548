/**
 * Get the next duplicate name in a list of existing names.
 * @param name The name to duplicate.
 * @param existingNames The list of existing names.
 *
 * @returns the next duplicate name within a list.
 * "Title_1" -> "Title_1(2)"
 * "Title_1(2)" -> "Title_1(3)"
 * "Title_1(3)" -> "Title_1(4)"
 */
export const getDuplicateName = (name: string, existingNames: string[] | undefined) => {
    // If there are no existing names, then technically there would be nothing to duplicate.
    if (!existingNames || existingNames.length === 0) {
        return name;
    }

    const baseName = name.replace(/\(\d+\)$/, "");
    const matchingNames = existingNames.filter((name) => name === baseName || name.startsWith(`${baseName}(`));

    // If there are no matching names, return the original name
    if (matchingNames.length === 0) {
        return name;
    }

    // If there's only one match and it's the exact base name, return baseName(2)
    if (matchingNames.length === 1 && matchingNames[0] === baseName) {
        return `${baseName}(2)`;
    }

    let highestNumber = 2;

    for (const name of matchingNames) {
        const match = name.match(/\((\d+)\)$/);
        if (match) {
            const number = parseInt(match[1], 10);
            highestNumber = Math.max(highestNumber, number);
        }
    }

    // Return the name with the next number
    return `${baseName}(${highestNumber + 1})`;
};
