import { Button, Tooltip } from "@edgetier/components";
import { faChevronDoubleLeft, faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";

import "./select-bulk-buttons.scss";
import { IProps } from "./select-bulk-buttons.types";
import { useMemo } from "react";

/**
 * Buttons to select all or no items.
 * @param props.notSelectedItemsCount         Number of items not yet selected.
 * @param props.isDisabled                    Whether the buttons are disabled.
 * @param props.onSelectAll                   Function to call when the "select all" button is pressed.
 * @param props.onSelectNone                  Function to call when the "select none" button is pressed.
 * @param props.selectedItemsCount            Number of items already selected.
 * @param props.limitReached                  Whether the limit of selected items has been reached.
 * @param props.hideBulkActionSelectAll       Whether to hide the "select all" bulk action button.
 * @param props.hideBulkActionDeselectAll     Whether to hide the "deselect all" bulk action button.
 * @param props.disableBulkActionSelectAll    Whether to disable the "select all" bulk action button.
 * @param props.disableBulkActionDeselectAll  Whether to disable the "deselect all" bulk action button.
 * @param props.selectAllDisabledTooltip      Tooltip to show when the "select all" button is disabled.
 * @param props.deselectAllDisabledTooltip    Tooltip to show when the "deselect all" button is disabled.
 * @returns                                   Component with two buttons.
 */
const SelectBulkButtons = ({
    notSelectedItemsCount,
    isDisabled = false,
    onSelectAll,
    onSelectNone,
    selectedItemsCount,
    limitReached,
    disableBulkActionSelectAll = false,
    disableBulkActionDeselectAll = false,
    selectAllDisabledTooltip,
    deselectAllDisabledTooltip,
    hideBulkActionSelectAll,
    hideBulkActionDeselectAll,
}: IProps) => {
    const isSelectAllDisabled = useMemo(() => {
        return notSelectedItemsCount === 0 || isDisabled || limitReached || disableBulkActionSelectAll;
    }, [notSelectedItemsCount, isDisabled, limitReached, disableBulkActionSelectAll]);

    const isDeselectAllDisabled = useMemo(() => {
        return selectedItemsCount === 0 || isDisabled || disableBulkActionDeselectAll;
    }, [selectedItemsCount, isDisabled, disableBulkActionDeselectAll]);

    return (
        <div className="select-bulk-buttons">
            {!hideBulkActionSelectAll && (
                <Tooltip
                    useArrow
                    content={selectAllDisabledTooltip}
                    disableTooltip={!isSelectAllDisabled || !selectAllDisabledTooltip}
                >
                    <Button
                        aria-label="Select all"
                        disabled={isSelectAllDisabled}
                        icon={faChevronDoubleRight}
                        isIconOnly={true}
                        onClick={onSelectAll}
                        outline={true}
                        title="Select all"
                    />
                </Tooltip>
            )}
            {!hideBulkActionDeselectAll && (
                <Tooltip
                    useArrow
                    content={deselectAllDisabledTooltip}
                    disableTooltip={!isSelectAllDisabled || !deselectAllDisabledTooltip}
                >
                    <Button
                        aria-label="Select none"
                        disabled={isDeselectAllDisabled}
                        icon={faChevronDoubleLeft}
                        isIconOnly={true}
                        onClick={onSelectNone}
                        outline={true}
                        styleName="negative"
                        title="Select none"
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default SelectBulkButtons;
