import { NumericValue } from "@edgetier/client-components";
import { FunctionComponent, memo } from "react";

import "./select-list-title.scss";
import { IProps } from "./select-list-title.types";

/**
 * Display a title above a select list.
 * @param props.count Number of items.
 * @param props.title Text description.
 * @param props.itemLimit Maximum number of items (Optional).
 * @returns           Title and number.
 */
const SelectListTitle: FunctionComponent<IProps> = ({ count, title, itemLimit, hasMore }) => {
    return (
        <div className="select-list-title">
            {title}
            <div className="select-list-title__count">
                {typeof itemLimit !== "undefined" && <span className="item-limit">Max {itemLimit}</span>}
                <span>
                    (<NumericValue value={count} unit={hasMore ? "+" : undefined} />)
                </span>
            </div>
        </div>
    );
};

export default memo(SelectListTitle);
