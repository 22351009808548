import { FunctionComponent, memo } from "react";
import { IProps } from "./ai-summary-secondary-icon.types";
import classNames from "classnames";

/**
 * Custom secondary icon to be used for AI related features
 * @param className optional param to apply classNames to icon to style it.
 * @returns {JSX.Element} AiSummarySecondaryIcon component.
 */
const AiSummarySecondaryIcon: FunctionComponent<IProps> = ({ className, svgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            className={classNames(className)}
            {...svgProps}
        >
            <rect width="36" height="36" rx="18" fill="url(#paint0_linear_6652_956)" />
            <ellipse
                cx="18.1885"
                cy="11.0187"
                rx="3.01887"
                ry="3.01887"
                transform="rotate(-90 18.1885 11.0187)"
                fill="white"
            />
            <ellipse
                cx="18.1885"
                cy="24.9811"
                rx="3.01887"
                ry="3.01887"
                transform="rotate(-90 18.1885 24.9811)"
                fill="white"
            />
            <ellipse
                cx="11.0189"
                cy="17.8112"
                rx="3.01887"
                ry="3.01887"
                transform="rotate(-90 11.0189 17.8112)"
                fill="white"
            />
            <ellipse
                cx="24.981"
                cy="17.8112"
                rx="3.01887"
                ry="3.01887"
                transform="rotate(-90 24.981 17.8112)"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6652_956"
                    x1="29.7818"
                    y1="4.90909"
                    x2="5.23636"
                    y2="31.0909"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BD36E" />
                    <stop offset="1" stopColor="#419BDC" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default memo(AiSummarySecondaryIcon);
