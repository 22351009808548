import type { CSSProperties, PropsWithChildren } from "react";

export enum StatusType {
    Green = "green",
    Blue = "blue",
    LightTeal = "lightTeal",
    DarkTeal = "darkTeal",
    LightOrange = "lightOrange",
    MediumOrange = "mediumOrange",
    DarkOrange = "darkOrange",
    Red = "red",
    Gray = "gray",
}

type IProps = PropsWithChildren<{
    readonly className?: string;
    readonly type?: StatusType;
    readonly backgroundColor?: CSSProperties["color"];
    readonly textColor?: CSSProperties["color"];
}>;

export type IStatusProps = IProps;
