export enum Permission {
    ManagePhraseTriggers = 1,
    ManageUsers = 2,
    ViewAgent = 3,
    ViewAnomalies = 4,
    ViewExplore = 5,
    ViewLive = 6,
    ManageWritingIssues = 7,
    ManageInteractionTags = 8,
    ViewCustomReports = 9,
    ManageAgentTags = 11,
    AddOrRemoveEmotions = 12,
    DownloadInteractions = 13,
    ManageTeams = 14,
    ManageAllReviews = 15,
    ManageRoles = 16,
    ManageOwnReviews = 17,
    ViewReceivedReviews = 18,
    ViewAgentDetails = 19,
    ManageScorecards = 20,
}
