import { formatDistance } from "date-fns";

/**
 * Format the date as a relative time if it is within the last 7 days, otherwise just print the date.
 * @param date The date to format.
 * @returns The formatted date.
 */
export const formatDistanceDate = (date: Date) => {
    const now = new Date();
    const sevenDays = 7 * 24 * 60 * 60 * 1000;

    // If date is over 7 days ago from current date, just print the date
    if (now.getTime() - date.getTime() > sevenDays) {
        return date.toDateString();
    }

    // Otherwise, show as relative time
    return `${formatDistance(date, now)} ago`;
};
